import { AppLogo } from '@price-for-profit/drive-app-core';
import { useService } from '@price-for-profit/service-provider';
import clientLoginLogo from 'assets/client-login-logo.png';
import clientLogo from 'assets/client-logo.png';
import insightDriveAccountLogo from 'assets/insight-drive-account-logo.svg';
import insightLogoSrc from 'assets/insight-logo-color.svg';
import insightLogoWatermarkWhiteSrc from 'assets/insight-logo-watermark-white.svg';
import insightLogoWatermarkSrc from 'assets/insight-logo-watermark.svg';

export const headerClientLogo = <AppLogo src={clientLogo} alt='client logo' />;
export const loginClientLogo = <AppLogo src={clientLoginLogo} alt='client login logo' />;
export const insightLoginLogo = <AppLogo src={insightDriveAccountLogo} alt='insight logo' maxHeight='30px' />;
export const driveToProfitLogo = (
    <AppLogo src={`${process.env.PUBLIC_URL}/DRIVEtoPROFIT_Color.png`} alt='drive to profit logo' maxHeight='50px' />
);

export const insightLogo = <AppLogo src={insightLogoSrc} alt='insight logo' />;

export function InsightLogoWaterMark() {
    const {
        appConfig: { deployedEnvironment },
    } = useService();
    return (
        <AppLogo
            src={deployedEnvironment !== 'prod' ? insightLogoWatermarkWhiteSrc : insightLogoWatermarkSrc}
            alt={'insight logo watermark'}
        />
    );
}
